.new-tracking {
    display: block;
    width: 900px;
    margin: 50px auto;

    .back {
        font-weight: 600;
        padding: 10px;
        cursor: pointer;
        opacity: 0.8;
        margin: 10px 0;
        display: inline-block;

        &:hover {
            opacity: 1;
        }

        i {
            margin: 0 20px 0 0;
        }
    }

    .new-tracking-container  {
        display: flex;
        flex-direction: column;
        padding: 50px;
        background-color: white;
        box-shadow: 0px 5px 12px -12px rgb(0 0 0 / 29%);
        margin: 20px 0 0;
    
        h2 {
            margin: 10px 0;
        }
    
        .buttons-container {
            display: flex;
            justify-content: space-between;
        }

        .leave {
            display: flex;
            flex-direction: row;
            align-items: baseline;

            .group {
                margin: 25px 1rem 45px 0;

                &:first-child {
                    flex: 70%;
                }

                input {
                    margin: 0;
                }
            }
            
        }

        .travel {
            display: flex;
            flex-direction: row;
            align-items: baseline;

            .group {
                margin: 25px 1rem 45px 0;
                flex: 50%;

                input {
                    margin: 0;
                }
            }
        }

        .remove {
            i {
                width: 30px;
                height: 30px;
                line-height: 30px;
                text-align: center;
                border-radius: 15px;
                background-color: #ddd;
                color: white;
                cursor: pointer;
                display: block;

                &:hover {
                    background-color: black;
                }
            }
        }

        .selectable-table-container {
            table {
                width: 100%;
                border-collapse: collapse;
                margin: 0 0 50px;
    
                thead {
                    tr {
                        th {
                            padding: 15px 20px;
                            text-align: left;
                        }
                    }
                }
                tbody {
                    tr {
                        cursor: pointer;
    
                        td {
                            padding: 15px 20px;
                        }
        
                        &.active {
                            td {
                                background-color: #66ba58;
                                color: white
                            }
                        }
                    }
                }
            }
        }
    }
    }
    @media only screen and (max-width: 992px) {
        .new-tracking {
            width: 70%;
        }
      }

      @media only screen and (max-width: 768px) {
        .new-tracking {
            width: 90%;
        }
      }