.report {
    display: block;
    width: 900px;
    margin: 50px auto;

    .loading-container {
        background: #000;
        padding: 0 20px;
        border: 1px solid #000;
        height: 50px;
        cursor: pointer;
        position: relative;

        span {
          position: absolute;
          top: 0;
          bottom: 0;
          height: 50px;
          display: inline-block;
          line-height: 50px;
          color: white;
          font-weight: 600;
          right: 20px;
        }

        &:hover {
          span {
            color: black;
          }

          background: white;

            .lds-ellipsis div {
                background: #000;
            }
        }

        .lds-ellipsis {
            display: inline-block;
            position: relative;
            width: 80px;
            height: 50px;

        div {
            position: absolute;
            top: 20px;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: #fff;
            animation-timing-function: cubic-bezier(0, 1, 1, 0);
          }
          div:nth-child(1) {
            left: 8px;
            animation: lds-ellipsis1 0.6s infinite;
          }
          div:nth-child(2) {
            left: 8px;
            animation: lds-ellipsis2 0.6s infinite;
          }
          div:nth-child(3) {
            left: 32px;
            animation: lds-ellipsis2 0.6s infinite;
          }
          div:nth-child(4) {
            left: 56px;
            animation: lds-ellipsis3 0.6s infinite;
          }
          @keyframes lds-ellipsis1 {
            0% {
              transform: scale(0);
            }
            100% {
              transform: scale(1);
            }
          }
          @keyframes lds-ellipsis3 {
            0% {
              transform: scale(1);
            }
            100% {
              transform: scale(0);
            }
          }
          @keyframes lds-ellipsis2 {
            0% {
              transform: translate(0, 0);
            }
            100% {
              transform: translate(24px, 0);
            }
          }
        }
    }

    .report-container  {
        display: flex;
        flex-direction: column;
        padding: 50px;
        background-color: white;
        box-shadow: 0px 5px 12px -12px rgb(0 0 0 / 29%);
        margin: 20px 0 0;
    
        h2 {
            margin: 10px 0;
        }
    
        .buttons-container {
            display: flex;
            justify-content: space-evenly;
        }

        input.date {
            outline: none;
            border: 0;
            margin: 0 20px 0 0;
            font-size: 18px;
            font-family: "Roboto", sans-serif;
            border-bottom: 1px solid grey;
            padding: 10px 10px 10px 0px;
        }

        .group-container {
            display: flex;
            flex-direction: row;
            margin: 0 -1rem 3rem;

            .group {
                width: 50%;
                display: block;
                margin: 0 1rem;

                input {
                    display: block;
                    width: 100%;
                }
            }
        }
    }
}

@media only screen and (max-width: 992px) {
    .report {
        width: 70%;
    }
}

    @media only screen and (max-width: 768px) {
    .report {
        width: 90%;
    }
}