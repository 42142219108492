.dashboard {
    width: 1200px;
    margin: 50px auto;

    .page-navigation {
        margin: 30px 0 0;
        display: flex;
        justify-content: end;
        align-items: center;

        span {
            width: 50px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            margin: 0 10px;
            font-weight: 600;
            transition: all 150ms ease-in-out;
            border-radius: 5px;
            background-color: white;
            box-shadow: 0px 5px 12px -12px rgb(0 0 0 / 29%);

            &.active, &:hover {
                color: white;
                background-color: #66ba58;
            }

            &:last-child {
                margin: 0 0 0 10px;
            }
            
            &:first-child {
                margin: 0 10px 0 0;
            }
        }
    }

    .user-container {
        background-color: white;
        border-left: 3px solid #66ba58;
        box-shadow: 0px 5px 12px -12px rgb(0 0 0 / 29%);
        margin: 0 0 10px;
        display: block;
        padding: 30px 50px;

        h4 {
            margin: 0;
            font-weight: 400;
            font-size: 20px;
        }

        p {
            margin: 10px 0 0;

            span {
                color: #66ba58;
            }
        }
    }

    .dashboard-container  {
        background-color: white;
        box-shadow: 0px 5px 12px -12px rgb(0 0 0 / 29%);

        .dashboard-header {
            display: flex;
            justify-content: space-between;
            padding: 50px;
            position: relative;
            margin: 20px 0 0;
            z-index: 9;

            .right-menu {
                display: flex;
                justify-content: flex-end;                

                #date {
                    outline: none;
                    border: 0!important;
                    font-weight: 600;
                    margin: 0 20px 0 0;
                    font-size: 16px;
                    font-family: "Roboto", sans-serif;
                }

                span {
                    font-weight: 600;
                    padding: 10px;
                    cursor: pointer;
                    display: inline-block;

                    i {
                        margin: 0 0 0 20px;
                    }
                }

                .dropdown-wrapper {
                    position: relative;
                    display: inline-block;
                
                    
                    &:focus-within .dropdown {
                        display: block;
                    }

                    .dropdown {
                        display: none;
                        position: absolute;
                        top: 40px;
                        background: white;
                        z-index: 99;
                        left: 0;
                        box-shadow: 0px 5px 12px -6px rgba(0, 0, 0, 0.29);
                        border-radius: 5px;
                    
                    .dropdown-container {
                        height: 400px;
                        width: 300px;
                        display: block;
                        overflow: auto;
                        
                        span {
                        display: block;
                        padding: 15px;
                        font-weight: normal;
                        cursor: pointer;
                        background-color: #ffffff;
                        transition: all 0.1s;
                
                        &:hover, &.is-active {
                            background-color: #66ba58;
                            color: #fff;
                        }
                        }
                    }
                    }
                }
    
                .react-datepicker-wrapper {
                    width: initial;
                    position: relative;
    
                    input {
                        padding: 10px;
                        font-size: 14px;
                        width: 120px;
                        font-weight: 600;
                        border: none;
                    }

                    &::after {
                        font-family: "Font Awesome 5 Free";
                        color: black;
                        font-size: 14px;
                        content: "\f073";
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        right: 10px;
                    }
    
                }
            }

            h2 {
                margin: 0;
            }
        }

        .table-container {
            table {
                table-layout: fixed;
                width: 100%;
            
                td, th {
                    border: none;
                    padding: 30px;
                    font-size: 18px;
                    vertical-align: middle;
                    text-align: left;
                }
                
                td, th {
                    padding: 30px 15px;

                    &:nth-child(1), &:nth-child(1) {
                        padding: 30px;
                        width: 80px;
                    }


                    &:nth-child(3), &:nth-child(3) {
                        width: 15%;
                    }

                    &:nth-child(2), &:nth-child(4), &:nth-child(2), &:nth-child(4) {
                        width: 20%;
                    }
                }
            
                tbody {
                    
                    tr:hover {
                        background-color: #f7f7f7;
                    }
                }
            }
        }

        .category-container {
            height: 70px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #66ba58;
            margin: 30px 0 0;

            span {
                color: white;
                font-weight: 400;
                cursor: pointer;
                height: 70px;
                align-items: center;
                display: flex;
                width: 150px;
                justify-content: center;
                border-bottom: 3px solid transparent;
                border-top: 3px solid transparent;

                &.active {
                    border-bottom-color: white;
                    font-weight: 600;
                }
            }
        }
    }
}

.dropdown-outside {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background-color: black;
}

@media only screen and (max-width: 992px) {
    .dashboard {
        width: 100%;

        .dashboard-container {

            .table-container {
                display: block;
                overflow-x: auto;

                table {
                    width: 992px;
                }
            }
        }
    }
}


@media only screen and (max-width: 768px) {
    .dashboard {
        .dashboard-container  {
            .dashboard-header {
                flex-direction: column;

                .right-menu {
                    justify-content: center;
                    margin: 30px 0 0;
                }
            }
        }
    }
}