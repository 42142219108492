.navigation-container {
  height: 70px;
  width: 100%;
  margin-bottom: 25px;

  .navigation {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo-container {
      height: 100%;
      display: flex;
      align-items: center;
      height: 80px;

      img {
        max-width: 60px;
      }

      h1 {
        font-size: 24px;
        margin: 0 0 0 20px;
      }
    }

    .nav-links-container {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .nav-link {
        padding: 10px 15px;
        cursor: pointer;
        font-weight: 600;
        position: relative;
        transition: all 0.1s ease-in-out;

        ul {
          display: none;
          position: absolute;
          top: 39px;
          background-color: white;
          list-style: none;
          padding: 0;
          right: 0;
          box-shadow: 0px 5px 12px -12px rgba(0, 0, 0, 0.5);
          margin: 0;
          padding: 0;
          z-index: 99;

          .nav-head {
            text-align: center;
            padding: 3rem 30px;
            margin: 0;
            cursor: default;

            .avatar {
              width: 60px;
              height: 60px;
              border-radius: 30px;
              background-color: #f8f9fd;
              margin: 0 auto 1rem;
              display: block;
              line-height: 60px;
              font-size: 30px;
              font-weight: bolder;
              color: #ddd;
            }

            h3 {
              margin: 0 0 0.5rem;
              color: black;
            }
            span {
              font-weight: normal;
              color: #66ba58;
            }
          }

          li {
            a {
              width: 300px;
              display: block;
              text-align: left;
              padding: 15px 30px;
              white-space: nowrap;
              transition: all 0.1s ease-in-out;

              &:hover {
                background-color: #66ba58;
                color: white;
              }
            }
          }
        }

        &:hover {
          color: #66ba58;
          ul {
            display: block;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 992px) {
  .navigation-container {
    .navigation {
      width: 100%;
    }
  }
}
@media only screen and (max-width: 768px) {
  .navigation-container {
    height: initial;
    .navigation {
      flex-direction: column;

      .nav-links-container {
        width: 100%;
        justify-content: center;
      }
    }
  }
}