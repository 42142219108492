.remarks-item {
    padding: 1.5rem 0;

    .office {
        border: 1px solid #66ba58;
        color: #66ba58;
        padding: 3px 5px;
        margin: 0 0 0 0.5rem;
        font-size: 12px;
    }

    .name {
        font-weight: 600;
    }

    .date {
        font-size: 12px;
        float: right;
    }

    .delete-container {
        text-align: right;
        margin: 0;

        .delete {
            cursor: pointer;
            font-size: 14px;
            visibility: hidden;
            opacity: 0;
            transition: ease-in all 0.1s;
            font-weight: 600;
        }
    }

    &:hover {
        .delete-container {
            .delete {
                visibility: visible;
                opacity: 1;

                &.hidden {
                    visibility: hidden;
                    opacity: 0;
                }
            }
        }
    }
}