.unauthorized-container  {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50vh;
    margin-top: 30vh;

    div {
        width: 500px;
        height: 500px;
        text-align: center;

        button {
            margin: 0 auto;
        }
    }
}