body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f9fd;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
  color: black;
}

* {
  box-sizing: border-box;
}

.modal {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 99;

  .modal-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    width: 80%;
    margin: 0 auto;

    button {
      margin: 0.5rem;
    }
  }
}

.is-clickable {
  cursor: pointer;
}

.is-inline-block {
  display: inline-block;
}

.back {
  font-weight: 600;
  padding: 10px;
  cursor: pointer;
  opacity: 0.8;
  margin: 10px 0;
  display: inline-block;

  &:hover {
      opacity: 1;
  }

  i {
      margin: 0 20px 0 0;
  }
}