.search-container {
    padding: 0 50px;
    
    .search {
        height: 70px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        padding: 0 20px;
        border-radius: 5px;
        border: 2px solid #f1f1f1;

        input {
            height: 100%;
            width: 100%;
            border: none;
            background: transparent;
            font-size: 18px;
            color: black;
            position: relative;
        
            &::placeholder {
                color: rgba(0,0,0,0.6);
            }

            &:focus {
                outline: none;
            }
        }

        &::before {
            font-family: 'Font Awesome 5 Free';
            content: '\f002';
            font-weight: 600;
            height: 70px;
            display: block;
            text-align: center;
            line-height: 70px;
            color: rgba(0,0,0,0.6);
            font-size: 18px;
            margin-right: 20px;
        }

        &:focus-within {
            border: 2px solid #66ba58;

            &::before {
                color: #66ba58;
            }
        }
    }
}