.admin {
    width: 1200px;
    margin: 50px auto;

    .office-container  {
        display: flex;
        flex-wrap: wrap;
        
        .office-item {
            flex: 33.333333%;
            padding: 1rem;

            .office-wrapper {
                background-color: white;
                box-shadow: 0px 5px 12px -12px rgb(0 0 0 / 29%);
                text-align: center;
                padding: 3rem 1rem;
                height: 280px;

                .button-container {
                    margin: 2rem auto 0;
                }
            }
        }

    }

    .stats-container  {
        display: flex;
        flex-wrap: wrap;
        background-color: white;
        box-shadow: 0px 5px 12px -12px rgb(0 0 0 / 29%);
        margin: 1rem;
        padding: 50px;

        h2 {
            flex: 100%;
            margin: 0 0 2rem;
        }
        
        .stats-item {
            flex: 20%;

            .stats-wrapper {
                text-align: center;
                padding: 2rem 1rem;

                h2 {
                    margin: 0;
                }
            }
        }

    }
}

@media only screen and (max-width: 992px) {
    .admin {
        width: 100%;
    }
}


@media only screen and (max-width: 768px) {
}