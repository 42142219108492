.tracking  {
    display: block;
    width: 1200px;
    margin: 50px auto;

    #print {
        display: block;
        position: absolute;
        bottom: 3000px;
        width: 1400px;
        padding: 0 0 40px;

        .routing-img {
            width: 100%;
            margin: 40px auto 0;
            display: block;
        }

        p {
            font-weight: 600;
            text-align: center;
            font-size: 28px;
            margin: 0;
        }

        .print-header {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            text-align: center;

            p {
                font-weight: 600;
                margin: 0 0 5px;
                font-size: 28px;
            }

            span {
                font-size: 28px;
            }
        }

        table {
            width: 100%;
            margin: 40px auto 0;
            border-collapse: collapse;
            font-size: 24px;

            tr {
                td {
                    border: 1px solid black;
                    padding: 5px 10px;
                }

            }

            &.slip {
                tr {
                    vertical-align: top;
    
                    td:nth-child(1) {
                        width: 250px;
                        font-weight: 600;
                    }
    
                    td:nth-child(3) {
                        width: 220px;
                        height: 220px;
                        padding: 0px 15px;
    
                        svg {
                            max-height: 100%;
                            max-width: 100%;
                        }
                    }
                }
            }
        }
    }

    .remarks-container {
        h3 {
            margin: 3rem 0;
        }

        .remarks-item {
            border-bottom: 1px solid #eee;

            &:last-child {
                border-bottom: 0px;
            }
        }
    }

    .tracking-container  {
        padding: 50px;
        background-color: white;
        box-shadow: 0px 5px 12px -12px rgb(0 0 0 / 29%);
        margin: 20px 0 0;
        display: flex;
        flex-direction: row-reverse;

        .tracking-item {
            max-width: 75%;
            flex: 75%;

            .tracking-header {
                margin: 0 0 10px;

                h2 {
                    margin: 0 0;
                    display: inline;
                }
            }
        
            .bordered {
                float: right;
                border: 1px solid #66ba58;
                color: #66ba58;
                padding: 5px 10px;

                &.primary {
                    border-color: #ffb200;
                    color: #ffb200;
                }

                &.print {
                    margin: 0 0.5rem 0 0;
                    border-color: black;
                    color: black;
                    cursor: pointer;
                }
            }
        }
    
        .tracking-qr {
            flex: 25%;
            text-align: center;
            position: relative;
            z-index: 9;
            padding: 0 0 0 30px;
    
            canvas {
                display: none;
            }
    
            svg {
                margin: 0 auto 30px;
            }
    
            .buttons-container {
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 0 20px;
            }
        }
    }
}


  .tracking-history {
    margin: 50px 0 0;

    .tracking-item {
        border-left: 3px solid #66ba58;
        padding: 0 30px 50px 80px;
        margin: 0 0 0 30px;
        position: relative;
    
        h3 {
            margin: 0;
        }

        &::after {
            position: absolute;
            font-family: 'Font Awesome 5 Free';
            content: '';
            font-weight: 800;
            width: 30px;
            height: 30px;
            display: block;
            left: -17px;
            top: 0;
            text-align: center;
            line-height: 30px;
            color: white;
            font-size: 14px;
        }
        
        &::before {
            content: "";
            display: block;
            position: absolute;
            left: -17px;
            width: 30px;
            height: 30px;
            border-radius: 15px;
            box-sizing: border-box;
            background-color: white;
            border: 3px solid #66ba58;
          }

          &.active {

            &::after {
                content: '\f00c';
                color: #66ba58;
            }
          }

          &.last {
            border-color: transparent;
          }
      }

      &.not-tracking {
        .tracking-item {
            border-left: 3px solid transparent;
    
            &::after {
                content: '\f00c';
                color: #66ba58;
            }
          }
      }
}

#qr {
    svg {
        display: block!important;
        float: none!important;
    }
}
@media only screen and (max-width: 992px) {
    .tracking {
        width: 90%;

        .tracking-container {
            flex-direction: column;
            .tracking-item {
                flex: 100%;
                .tracking-header {
                    h2 {
                        display: block;
                        margin: 20px 0 10px 0;
                    }
                    .bordered {
                        float: none;
                    }
                }
            }

            .tracking-qr {
                flex: 100%;
                margin: 0 0 100px;
                padding: 0;
            }
        }
    }
  }