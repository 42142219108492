
.main-container {
    display: flex;
    flex-direction: row;
    margin: 50px auto;
    width: 1200px;

    .login-container  {
        width: 600px;
        background-color: white;
        box-shadow: 0px 5px 12px -12px rgb(0 0 0 / 29%);
        padding: 50px;
    
        h2 {
            margin: 10px 0;
        }
    
        .buttons-container {
            display: flex;
            justify-content: space-between;
        }
    }
    
    .download-container  {
        width: 600px;
        padding: 50px;
        text-align: center;
    
        img {
            width: 30%;
            margin: 0 auto 3rem;
        }

        button {
            margin: 0 auto;
        }
    }
}

@media only screen and (max-width: 992px) {
    .main-container {
        width: 90%;

      .login-container, .download-container {
        width: 50%;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .main-container {
      height: initial;
      flex-direction: column-reverse;

      .login-container {
        width: 100%;
      }

      .download-container {
        width: 100%;
        margin: 50px 0 0;
      }
    }
  }