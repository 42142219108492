.admin-stats {
    width: 1200px;
    margin: 50px auto;

    .stat-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 4rem 0 2rem;

        .stat-item {
            width: 33.33333%;
            text-align: center;
        }
    }

    .stats-container  {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        background-color: white;
        box-shadow: 0px 5px 12px -12px rgb(0 0 0 / 29%);
        margin: 1rem;
        padding: 50px;
        align-items: center;

        h2 {
            flex: 100%;
            margin: 0 0 2rem;
        }

        .group {
            width: 25%;
            margin: 0;
            padding: 0 1rem;

            select {
                margin: 0;
            }
        }

    }

    .filter-container {
        .filter {
            background-color: white;
            box-shadow: 0px 5px 12px -12px rgb(0 0 0 / 29%);
            margin: 1rem;
            padding: 50px;
            align-items: center;

            h3 {
                .remove {
                    padding: 0.25rem 0.5rem;
                    float: right;
                    cursor: pointer;
                    font-size: 14px;
                }
            }
        }
    }

    input.date {
        width: 100%;
        outline: none;
        border: 0;
        font-size: 18px;
        font-family: "Roboto", sans-serif;
        border-bottom: 1px solid grey;
        padding: 10px 10px 10px 0px;
    }
}

@media only screen and (max-width: 992px) {
    .admin-stats {
        width: 100%;

        .stats-container {
        h2 {
            margin: 0 0 1rem 0;
            width: 100%;
        }

        .group {
            width: 100%;
            margin: 0 0 1rem 0;
            padding: 0;

            select {
                margin: 0;
            }
        }
        }
    }
}


@media only screen and (max-width: 768px) {
}