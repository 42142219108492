.purchase-request {
    display: block;
    width: 700px;
    margin: 50px auto;

    .back {
        font-weight: 600;
        padding: 10px;
        cursor: pointer;
        opacity: 0.8;
        margin: 10px 0;
        display: inline-block;

        &:hover {
            opacity: 1;
        }

        i {
            margin: 0 20px 0 0;
        }
    }

    .purchase-request-container  {
        display: flex;
        flex-direction: column;
        padding: 50px;
        background-color: white;
        box-shadow: 0px 5px 12px -12px rgb(0 0 0 / 29%);
        margin: 20px 0 0;
    
        h2 {
            margin: 10px 0;
        }
    
        .buttons-container {
            display: flex;
            justify-content: space-between;
        }
    }
    }
    @media only screen and (max-width: 992px) {
        .purchase-request {
            width: 70%;
        }
      }

      @media only screen and (max-width: 768px) {
        .purchase-request {
            width: 90%;
        }
      }